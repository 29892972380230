import { AuthHelper } from '@shared/auth/AuthHelper';
import { initializeLocalization } from '@shared/i18n';
import { setupLogging } from '@shared/logging/appInsights';
import { logError } from '@shared/logging/logging';
import { setupSpartaApi } from '@shared/spartaApiPicker';

try {
	window.__$ver = `build: ${__$buildNumber} | build time: ${__$buildTime}`;

	setupLogging();

	setupSpartaApi();

	const locPromise = initializeLocalization();
	const bootImport = locPromise.then(async () => import(/* webpackChunkName: "Boot", webpackPreload: true */ './boot'));

	AuthHelper.initialize({ tenantName: window.siteConfig.tenantName, tenantId: window.siteConfig.tenantId }, async (user) => {
		// wait for localization to finish initialization before rendering anything
		await locPromise;

		const { boot } = await bootImport;
		await boot(user);
	});
} catch (e) {
	logError(e);
}
