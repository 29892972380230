import { ApiName } from '@api/base/ApiName';

import {
	LOCALSTORAGE_SPARTA_API,
	LOCALSTORAGE_SPARTA_BASE_URL,
	USE_CUSTOM,
	USE_ENDR,
	USE_INTZ,
	USE_LOCAL,
	USE_NS,
	USE_NS1,
	USE_PERF,
	USE_PERF2,
	USE_POC,
	USE_TEST,
} from './constants';
import { getApiConfig } from './utilities/config';

export enum SPARTA_API_CONSTANTS {
	Custom = 'Custom',
	Poc = 'Poc',
	Perf2 = 'Perf2',
	Perf = 'Perf',
	Test = 'Test',
	IntZ = 'IntZ',
	Endr = 'Endr',
	Local = 'Local',
}

export const queryStringToSpartaApiMap = {
	[USE_PERF2]: SPARTA_API_CONSTANTS.Perf2,
	[USE_PERF]: SPARTA_API_CONSTANTS.Perf,
	[USE_POC]: SPARTA_API_CONSTANTS.Poc,
	[USE_TEST]: SPARTA_API_CONSTANTS.Test,
	[USE_INTZ]: SPARTA_API_CONSTANTS.IntZ,
	[USE_ENDR]: SPARTA_API_CONSTANTS.Endr,
	[USE_LOCAL]: SPARTA_API_CONSTANTS.Local,
	[USE_NS]: SPARTA_API_CONSTANTS.Custom,
	[USE_NS1]: SPARTA_API_CONSTANTS.Custom,
	[USE_CUSTOM]: SPARTA_API_CONSTANTS.Custom,
};

export interface SpartaApis {
	Custom: SpartaApi;
	Poc: SpartaApi;
	Perf2: SpartaApi;
	Perf: SpartaApi;
	Test: SpartaApi;
	IntZ: SpartaApi;
	Endr: SpartaApi;
	Local: SpartaApi;
}

export interface SpartaApi {
	Environments: string;
	Hierarchy: string;
	Provision: string;
	Tenants: string;
	ProvisioningV2: string;
}

/**
 * @returns true only for dev environments (e.g., local, dev-dev9)
 */
export const hasAtLeastOneSpartaApi = () => {
	return window.spartaApis && Object.values(window.spartaApis).some((v) => v !== null);
};

export const removeSpartaApi = () => {
	localStorage.removeItem(LOCALSTORAGE_SPARTA_API);
};

export const getSpartaApiEnv = () => {
	/**
	 * Sparta API picker is not applicable for PROD/INT (none sparta APIs returned in window.spartaApis).
	 * Remove LOCALSTORAGE_SPARTA_API for PROD/INT to avoid confusion
	 */
	if (!hasAtLeastOneSpartaApi()) {
		removeSpartaApi();

		return null;
	}

	return localStorage.getItem(LOCALSTORAGE_SPARTA_API);
};

export const getSpartaEnvBaseUrl = () => {
	return localStorage.getItem(LOCALSTORAGE_SPARTA_BASE_URL);
};

export const setSpartaEnvBaseUrl = (prefix: string) => {
	localStorage.setItem(LOCALSTORAGE_SPARTA_BASE_URL, prefix);
};

const removeSpartaEnvBaseUrl = () => {
	localStorage.removeItem(LOCALSTORAGE_SPARTA_BASE_URL);
};

export const getSpartaApiUrls = (spartaApiEnv: string = getSpartaApiEnv(), baseUrl?: string) => {
	switch (spartaApiEnv) {
		case SPARTA_API_CONSTANTS.Custom: {
			const prefix = baseUrl ?? getSpartaEnvBaseUrl();

			const urls = Object.keys(window.spartaApis.Custom).reduce((accumulator, key) => {
				return { ...accumulator, [key]: window.spartaApis.Custom[key].replace('{baseUrl}', prefix) };
			}, {});

			return urls as SpartaApi;
		}
		case SPARTA_API_CONSTANTS.Poc:
			return window.spartaApis.Poc;
		case SPARTA_API_CONSTANTS.Perf2:
			return window.spartaApis.Perf2;
		case SPARTA_API_CONSTANTS.Perf:
			return window.spartaApis.Perf;
		case SPARTA_API_CONSTANTS.Test:
			return window.spartaApis.Test;
		case SPARTA_API_CONSTANTS.IntZ:
			return window.spartaApis.IntZ;
		case SPARTA_API_CONSTANTS.Endr:
			return window.spartaApis.Endr;
		case SPARTA_API_CONSTANTS.Local:
			return window.spartaApis.Local;
		default:
			return null;
	}
};

export const getSpartaApiUrlByApiName = (apiName: ApiName): string => {
	const config = getApiConfig(apiName);

	if (config.BaseUriReference) {
		return getSpartaApiUrlByApiName(config.BaseUriReference);
	}

	return getSpartaApiUrls()?.[apiName] ?? '';
};

export const setSpartaApiEnvInLocalStorage = (spartaApi: string) => {
	if (spartaApi !== SPARTA_API_CONSTANTS.Custom) {
		removeSpartaEnvBaseUrl();
	}

	localStorage.setItem(LOCALSTORAGE_SPARTA_API, spartaApi);
};

export const setupSpartaApi = () => {
	const searchParams = new URLSearchParams(window.location.search.toLocaleLowerCase());

	// Read from local storage by default because this method is called when page reloads (e.g., user types /diagnostics in URL)
	let spartaApiToSet = getSpartaApiEnv();

	if ([...searchParams].length) {
		if (searchParams.get(USE_INTZ)?.toLocaleLowerCase() === 'true') {
			removeSpartaApi();

			return;
		}

		for (const [queryString, spartaApi] of Object.entries(queryStringToSpartaApiMap)) {
			const queryStringValue = searchParams.get(queryString);

			if (queryStringValue?.toLocaleLowerCase() === 'true') {
				spartaApiToSet = spartaApi;
				break;
			} else if ([USE_NS, USE_NS1, USE_CUSTOM].includes(queryString) && !!queryStringValue) {
				spartaApiToSet = spartaApi;
				if (queryString === USE_NS) {
					setSpartaEnvBaseUrl(`https://perfpme.api.dfp.dynamics-int.com/proxy/nstestperf2wus-release2-${queryStringValue}`);
				} else if (queryString === USE_NS1) {
					setSpartaEnvBaseUrl(`https://perfpme.api.dfp.dynamics-int.com/proxy/nstestperf2wus-release-${queryStringValue}`);
				} else if (queryString === USE_CUSTOM) {
					setSpartaEnvBaseUrl(queryStringValue);
				}
				break;
			}
		}
	}

	if (spartaApiToSet) {
		setSpartaApiEnvInLocalStorage(spartaApiToSet);
	}
};

export const shouldUseDevEndpoints = () => {
	const spartaApiDevEnvs = Object.values(SPARTA_API_CONSTANTS).filter((k) => k !== SPARTA_API_CONSTANTS.IntZ) as string[];

	return spartaApiDevEnvs.includes(getSpartaApiEnv());
};

export const shouldUseIntZEndpoints = () => {
	return getSpartaApiEnv() === SPARTA_API_CONSTANTS.IntZ;
};
